import {
  ChakraProvider,
  Box,
  theme,
  Container,
  Text,
  Link,
} from "@chakra-ui/react";
import NavigationBar from "./components/NavigationBar";
import Questionnaire from "./pages/Questionnaire";
import useLocalStorage from "./hooks/useLocalStorage";
import { RESULTS_STORAGE_KEY } from "./utils/constants";
import Results from "./pages/Results";
import { useEffect, useState } from "react";
import Landing from "./pages/Landing";
import { ExternalLinkIcon } from "@chakra-ui/icons";

export const App = () => {
  const { value, setValueInLocalStorage, removeValueInLocalStorage } =
    useLocalStorage<{
      [key: string]: number;
    }>(RESULTS_STORAGE_KEY);
  const showResults = value;

  const [showLanding, setShowLanding] = useState(true);

  const storeResultsHandler = (val: { [key: string]: number }) => {
    setValueInLocalStorage(val);
  };

  const startQuestionnaire = () => {
    setShowLanding(false);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [value]);

  return (
    <ChakraProvider theme={theme}>
      <Box textAlign="center" fontSize="xl">
        <Box minH="100vh" p={3} display="flex" flexDirection="column">
          <NavigationBar justifySelf="flex-end" />
          {showResults ? (
            <Results results={value} resetResults={removeValueInLocalStorage} />
          ) : showLanding ? (
            <Landing startQuestionnaire={startQuestionnaire} />
          ) : (
            <Questionnaire storeResults={storeResultsHandler} />
          )}
          <Container as="footer" role="contentinfo" pt={4} pb={8}>
            <Text fontSize="sm" color="gray.500">
              Questions from{" "}
              <Link
                isExternal
                href="https://www.lifeway.com/en/articles/women-leadership-spiritual-gifts-growth-service"
              >
                Lifeway Christian Resources
                <ExternalLinkIcon mx="2px" />
              </Link>
              .
            </Text>
            <Text fontSize="sm" color="gray.500">
              Camp Beyond 2023
            </Text>
          </Container>
        </Box>
      </Box>
    </ChakraProvider>
  );
};
