import { Box, Heading, Text, VStack } from "@chakra-ui/react";
import ResultCards from "../components/ResultCards";
import giftDescriptions from "../data/giftDescriptions.json";
import { useEffect } from "react";

const Results: React.FC<{
  results: { [key: string]: number };
  resetResults: () => void;
}> = ({ results, resetResults }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const giftDescriptionsObject = Object(giftDescriptions);

  const sortedResults = Object.entries(results);
  sortedResults.sort((a, b) => b[1] - a[1]); // descending

  const resultsObject = sortedResults.map((result) => ({
    title: result[0],
    score: result[1],
    description: giftDescriptionsObject[result[0]],
  }));

  return (
    <VStack>
      <Heading>Your Spiritual Gifts</Heading>
      <Box width="100%">
        <ResultCards results={resultsObject} />
      </Box>
      <Box mt={5}>
        <Text
          fontSize="sm"
          textDecoration="underline"
          onClick={resetResults}
          _hover={{ cursor: "pointer" }}
          color="red.600"
        >
          Reset results
        </Text>
      </Box>
    </VStack>
  );
};

export default Results;
