import { useState, useEffect, useCallback } from "react";

export default function useLocalStorage<ValueType>(key: string) {
  const [value, setValue] = useState<ValueType | null>(() => {
    const storedValue = localStorage.getItem(key);

    return storedValue === null ? null : JSON.parse(storedValue);
  });

  useEffect(() => {
    const listener = (e: StorageEvent) => {
      if (e.storageArea === localStorage && e.key === key) {
        setValue(e.newValue ? JSON.parse(e.newValue) : e.newValue);
      }
    };
    window.addEventListener("storage", listener);

    return () => {
      window.removeEventListener("storage", listener);
    };
  }, [key]);

  const setValueInLocalStorage = useCallback(
    (newValue: ValueType) => {
      setValue((currentValue: ValueType | null) => {
        const result =
          typeof newValue === "function" ? newValue(currentValue) : newValue;

        localStorage.setItem(key, JSON.stringify(result));

        return result;
      });
    },
    [key],
  );

  const removeValueInLocalStorage = useCallback(() => {
    setValue(null);
    localStorage.removeItem(key);
  }, [key]);

  return { value, setValueInLocalStorage, removeValueInLocalStorage };
}
