import * as React from "react";
import {
  useColorMode,
  useColorModeValue,
  IconButton,
  IconButtonProps,
  Container,
} from "@chakra-ui/react";
import { FaMoon, FaSun } from "react-icons/fa";
import campBeyondLogo from "../assets/camp_beyond_logo.png";

type ColorModeSwitcherProps = Omit<IconButtonProps, "aria-label">;

const NavigationBar: React.FC<ColorModeSwitcherProps> = (props) => {
  const { toggleColorMode } = useColorMode();
  const text = useColorModeValue("dark", "light");
  const SwitchIcon = useColorModeValue(FaMoon, FaSun);

  return (
    <Container display="flex" justifyContent="space-between" p={0}>
      <Container display="flex" alignItems="center" p={0}>
        <img
          src={campBeyondLogo}
          alt="Camp Beyond Logo"
          style={{ height: "30px" }}
        />
      </Container>
      <IconButton
        size="md"
        fontSize="lg"
        variant="ghost"
        color="current"
        marginLeft="2"
        onClick={toggleColorMode}
        icon={<SwitchIcon />}
        aria-label={`Switch to ${text} mode`}
        {...props}
      />
    </Container>
  );
};

export default NavigationBar;
