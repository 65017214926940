import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Button,
  Link,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  UnorderedList,
  useDisclosure,
} from "@chakra-ui/react";

const PrefaceModal: React.FC = () => {
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Spiritual Gifts Survey</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>
            Let's find out more about the spiritual gifts God has blessed us
            with!
          </Text>
          <br />
          <Text as="u">Instructions:</Text>
          <Text>
            This is not a test, so there are no wrong answers. The Spiritual
            Gifts Survey consists of 80 statements. Some items reflect concrete
            actions, other items are descriptive traits, and still others are
            statements of belief.
          </Text>
          <UnorderedList>
            <ListItem>
              Select the option you feel best characterizes yourself.
            </ListItem>
            <ListItem>
              Do not spend too much time on any one item. Remember, it is not a
              test. Usually your immediate response is best.
            </ListItem>
            <ListItem>
              Please give an answer for each item. Do not skip any items.
            </ListItem>
            <ListItem>
              Do not ask others how they are answering or how they think you
              should answer.
            </ListItem>
            <ListItem>Work at your own pace.</ListItem>
          </UnorderedList>
          <br />
          <Text as="b">
            Please do not close your browser while the questionnaire is ongoing
            as progress is not saved.
          </Text>

          <br />
          <br />
          <Text color="gray.500">
            Questions from{" "}
            <Link
              isExternal
              href="https://www.lifeway.com/en/articles/women-leadership-spiritual-gifts-growth-service"
            >
              Lifeway Christian Resources
              <ExternalLinkIcon mx="2px" />
            </Link>
            .
          </Text>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Let's Begin!
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PrefaceModal;
