import {
  Box,
  Divider,
  HStack,
  Text,
  UseRadioProps,
  VStack,
  useRadio,
  useRadioGroup,
} from "@chakra-ui/react";
import { RadioOption } from "../@types/types";
import React, { ForwardedRef, useState } from "react";

type RadioGroupProps = {
  question: string;
  onChangeHandler: (selectedOption: RadioOption) => void;
};
const RadioGroup = React.forwardRef(
  (
    { question, onChangeHandler }: RadioGroupProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    // Chakra UI only accepts strings as option values for radio
    const optionsValues: RadioOption[] = ["1", "2", "3", "4", "5"];
    const [isSelected, setIsSelected] = useState(false);

    const { getRootProps, getRadioProps } = useRadioGroup({
      name: "rating",
      onChange: (e) => {
        onChangeHandler(e);
        setIsSelected(true);
      },
    });

    const group = getRootProps();

    return (
      <VStack
        width="100%"
        maxW="350px"
        pt={5}
        opacity={isSelected ? 0.2 : 1}
        ref={ref}
        scrollMarginTop={5}
      >
        <Text fontSize="md">{question}</Text>
        <HStack
          {...group}
          width="100%"
          maxW="300px"
          display="flex"
          justifyContent="space-between"
        >
          {optionsValues.map((value) => {
            const radio = getRadioProps({ value });
            return <RadioButton key={value} {...radio} option={value} />;
          })}
        </HStack>
        <HStack
          display="flex"
          justifyContent="space-between"
          width="100%"
          maxW="300px"
        >
          <Text fontSize="sm" color={optionValueToColorMap["1"].bg} mr={5}>
            Disagree
          </Text>
          <Text fontSize="sm" color={optionValueToColorMap["5"].bg} ml={5}>
            Agree
          </Text>
        </HStack>
        <Divider mt={3} />
      </VStack>
    );
  },
);

export default RadioGroup;

const RadioButton: React.FC<{ option: string } & UseRadioProps> = (props) => {
  const { getInputProps, getRadioProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getRadioProps();

  return (
    <Box
      as="label"
      cursor="pointer"
      borderRadius="50%"
      borderColor={optionValueToColorMap[props.option].bg}
      borderWidth="2px"
      _hover={optionValueToColorMap[props.option]}
      _checked={optionValueToColorMap[props.option]}
      height={optionValueToRadioButtonWidth[props.option]}
      width={optionValueToRadioButtonWidth[props.option]}
      {...checkbox}
    >
      <input {...input} />
    </Box>
  );
};

const optionValueToColorMap: {
  [key: RadioOption]: { bg: string; borderColor: string };
} = {
  "1": {
    bg: "orange.600",
    borderColor: "orange.600",
  },
  "2": {
    bg: "orange.600",
    borderColor: "orange.600",
  },
  "3": {
    bg: "gray.400",
    borderColor: "gray.400",
  },
  "4": {
    bg: "teal.600",
    borderColor: "teal.600",
  },
  "5": {
    bg: "teal.600",
    borderColor: "teal.600",
  },
};

const optionValueToRadioButtonWidth: { [key: RadioOption]: string } = {
  "1": "40px",
  "2": "35px",
  "3": "30px",
  "4": "35px",
  "5": "40px",
};
