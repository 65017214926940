import { TriangleUpIcon } from "@chakra-ui/icons";
import { Badge, Box } from "@chakra-ui/react";

type PercentageBadgeProps = {
  score: number;
};

const PercentageBadge: React.FC<PercentageBadgeProps> = ({ score }) => {
  const percentage = calculateResultPercentage(score);

  return (
    <Badge colorScheme={percentageToColor(percentage)} fontSize="0.8em">
      {percentage.toFixed(0)}%&nbsp;
      <Box as="span" display="inline-flex" alignItems="center">
        <TriangleUpIcon
          boxSize={2.5}
          display="inline-flex"
          alignItems="center"
        />
      </Box>
    </Badge>
  );
};

const TOTAL_SCORE_PER_CATEGORY = 25;
const calculateResultPercentage = (score: number) =>
  (score / TOTAL_SCORE_PER_CATEGORY) * 100;

const percentageToColor = (percentage: number) => {
  if (percentage >= 75) {
    return "green";
  } else if (percentage >= 50) {
    return "yellow";
  } else if (percentage >= 25) {
    return "orange";
  } else {
    return "red";
  }
};

export default PercentageBadge;
