import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Heading,
  Text,
} from "@chakra-ui/react";
import PercentageBadge from "./PercentageBadge";

type ResultCardProps = {
  results: {
    title: string;
    description: string;
    score: number;
  }[];
};

const ResultCards: React.FC<ResultCardProps> = ({ results }) => {
  return (
    <Accordion
      defaultIndex={Array.from({ length: 3 }, (_, i) => i)}
      allowMultiple
      width="100%"
      textAlign="left"
    >
      {results.map(({ title, description, score }, idx) => (
        <AccordionItem key={idx}>
          <AccordionButton width="100%">
            <Heading
              size="md"
              height="2em"
              as="span"
              flex="1"
              display="inline-flex"
              alignItems="center"
            >
              {title}&nbsp;
              <PercentageBadge score={score} />
            </Heading>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <Text fontSize="md">{description}</Text>
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
};

export default ResultCards;
