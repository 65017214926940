import { Button, Center, Container, Heading } from "@chakra-ui/react";
import LottieAnimation from "../components/LottieAnimation";
import "./Landing.css";

type LandingProps = {
  startQuestionnaire: () => void;
};

const Landing: React.FC<LandingProps> = ({ startQuestionnaire }) => {
  return (
    <Center height="100%" flex="1 1 auto">
      <Container>
        <LottieAnimation />
        <Container display="flex" justifyContent="center" width="min-content">
          <Heading
            style={{
              overflow: "hidden",
              borderRight: "0.15em solid orange",
              whiteSpace: "nowrap",
              margin: "0",
              animation: `typing 0.8s steps(40, end), blinkingCaret 0.75s step-end infinite`,
              width: "min-content",
            }}
          >
            You've Got A Gift!
          </Heading>
        </Container>
        <Button
          variant="outline"
          onClick={startQuestionnaire}
          mt={10}
          size="lg"
        >
          Start
        </Button>
      </Container>
    </Center>
  );
};

export default Landing;
