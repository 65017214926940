import { Box, Button, HStack, Progress, Text, VStack } from "@chakra-ui/react";
import questionData from "../data/questions.json";
import giftToQuestionMapping from "../data/giftToQuestionMapping.json";
import RadioGroup from "../components/RadioGroup";
import { createRef, useEffect, useMemo, useRef, useState } from "react";
import { QuestionIndex, RadioOption } from "../@types/types";
import { usePagination } from "react-use-pagination";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { QUESTIONS_PER_PAGE } from "../utils/constants";
import PrefaceModal from "../components/PrefaceModal";

const Questionnaire: React.FC<{ storeResults: (r: any) => void }> = ({
  storeResults,
}) => {
  const [results, setResults] = useState<{ [key: QuestionIndex]: RadioOption }>(
    {},
  );
  const questionDataArray = useMemo(() => Object.entries(questionData), []);
  const {
    currentPage,
    totalPages,
    setNextPage,
    // setPreviousPage,
    // nextEnabled,
    // previousEnabled,
    startIndex,
    endIndex,
  } = usePagination({
    totalItems: questionDataArray.length,
    initialPageSize: QUESTIONS_PER_PAGE,
  });

  const questionRefs = useRef(
    Array.from({ length: QUESTIONS_PER_PAGE }, () =>
      createRef<HTMLDivElement>(),
    ),
  );

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [currentPage]);

  const updateResultHandler =
    (questionOnPageIndex: number, questionIdx: QuestionIndex) =>
    (selectedOption: RadioOption) => {
      setResults({
        ...results,
        [questionIdx]: selectedOption,
      });
      questionRefs.current[questionOnPageIndex + 1]?.current?.scrollIntoView({
        behavior: "smooth",
      });
    };

  const isCurrentPageAllAttempted = () => {
    for (let i = startIndex; i <= endIndex; i++) {
      const currentEntry = questionDataArray[i];
      if (results[currentEntry[0]] === undefined) {
        return false;
      }
    }
    return true;
  };

  const consolidateTopThreeResults = () => {
    const attributeScores: { [key: string]: number } = {};
    Object.entries(giftToQuestionMapping).forEach(
      ([attribute, questionIndices]) => {
        questionIndices.forEach((idx) => {
          let questionScore = parseInt(results[idx], 10);
          questionScore = Number.isNaN(questionScore) ? 0 : questionScore;
          if (attributeScores[attribute]) {
            attributeScores[attribute] += questionScore;
          } else {
            attributeScores[attribute] = questionScore;
          }
        });
      },
    );
    storeResults(attributeScores);
  };

  const numberAttempted = Object.entries(results).length;
  const isLastPage = currentPage === totalPages - 1;

  return (
    <>
      <PrefaceModal />
      <VStack marginY={8}>
        {questionDataArray
          .slice(startIndex, endIndex + 1)
          .map(([questionIdx, question], idx) => (
            <RadioGroup
              key={questionIdx}
              ref={questionRefs.current[idx]}
              question={question}
              onChangeHandler={updateResultHandler(idx, questionIdx)}
            />
          ))}
        <HStack width="100%" maxW="350px" justifyContent="right">
          {isLastPage ? (
            <Button
              variant="outline"
              colorScheme="teal"
              rightIcon={<ArrowForwardIcon />}
              onClick={consolidateTopThreeResults}
              isDisabled={!isCurrentPageAllAttempted()}
            >
              Get results
            </Button>
          ) : (
            <Button
              variant="outline"
              colorScheme="teal"
              rightIcon={<ArrowForwardIcon />}
              onClick={setNextPage}
              isDisabled={!isCurrentPageAllAttempted()}
            >
              Next
            </Button>
          )}
        </HStack>
      </VStack>
      <Box
        position="fixed"
        bottom={0}
        left={0}
        width="100%"
        py={2}
        display="flex"
        justifyContent="center"
        bgColor="RGBA(0, 0, 0, 0.48)"
      >
        <HStack width="100%" maxW="350px">
          <Text fontSize="sm" color="white">
            {Math.floor((numberAttempted / questionDataArray.length) * 100)}%
          </Text>
          <Progress
            value={numberAttempted}
            max={questionDataArray.length}
            width="100%"
            colorScheme="teal"
          />
        </HStack>
      </Box>
    </>
  );
};

export default Questionnaire;
