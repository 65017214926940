import Lottie from "react-lottie";
import animationData from "../assets/lottie_gift.json";

const LottieAnimation: React.FC = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return <Lottie options={defaultOptions} height={250} width={250} />;
};

export default LottieAnimation;
